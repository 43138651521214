import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Search from './search';
import Press from './press';

const Hero = ({ contentModuleId }) => {

    const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutHero {
                edges {
                    node {
                        id
                        heading
                        subheading
                        description {
                            description
                        }
                        ctaText
                        ctaUrl
                        image {
                            fluid(quality: 100) {
                                ...GatsbyContentfulFluid
                            }
                            file {
                              url
                            }
                        }
                    }
                }
            }
        }
    `);

    const content = data.allContentfulLayoutHero.edges.find(edge => edge.node.id === contentModuleId);

    return (
      <div>
        <section className="hero container section mx-auto">
          <div className="hero__tagline">
            <div className="hero__tagline-content-wrap">
              <h2 className="hero__tagline-title" data-sal="fade">{ content.node.heading }</h2>
              <p className="hero__tagline-subtitle" data-sal="fade" data-sal-delay="100">{ content.node.subheading }</p>
              <p className="hero__tagline-text" data-sal="fade" data-sal-delay="200">{ content.node.description.description }</p>
            </div>
          </div>
          <div className="hero__image">
            <img src={ content.node.image.file.url }  className="mx-auto" alt="Hero" data-sal="slide-right" data-sal-delay="400" data-sal-duration="500" />
          </div>
        </section>
        <div className="pb-24">
          <div className="flex justify-center">
            <div className="bg-secondaryBlue text-white text-left py-1 px-4 mb-3">
              <b>Stimme ab:</b> In welcher Stadt und wie häufig kannst du dir vorstellen unser Angebot zu nutzen?
              <br/> Ab <b>Frühjahr 2022</b> starten wir mit unserem Angebot.
            </div>
          </div>
          <Search />
        </div>
        <Press />
      </div>

    );
};

Hero.propTypes = {
    contentModuleId : PropTypes.string.isRequired
}

export default Hero;
