import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import icon_hotel from '../assets/svg/icon_hotel.svg'
import icon_abo from '../assets/svg/icon_abo.svg'
import icon_CO2 from '../assets/svg/icon_CO2.svg'
import icon_konstant from '../assets/svg/icon_konstant.svg'
import icon_sparen from '../assets/svg/icon_sparen.svg'
import icon_steuer from '../assets/svg/icon_steuer.svg'

const Advantages = ({ contentModuleId }) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulLayoutAbout {
        edges {
          node {
            id
            heading
            description {
              description
            }
            featureItem {
              id
              title
              icon
              description {
                description
              }
            }
            image {
              fluid(quality:100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutAbout.edges.find(edge => edge.node.id === contentModuleId);

  return (
    <section id="vorteile" className="bg-warmGray">
      <div className="mx-auto py-16">
        <div className="text-center">
          <h2 className="section__title" data-sal="fade" data-sal-easing="ease-in-cubic">{ content.node.heading } </h2>
          <h3 className="section__subtitle" data-sal="slide-up" data-sal-easing="ease-in-cubic" data-sal-delay="100">{ content.node.description.description }</h3>

            <div className="flex leading-normal font-light justify-center items-center flex-col sm:flex-row lg:flex-col">

              <div className="flex flex-wrap justify-center flex-col w-64 sm:flex-row lg:w-full">

                <div className="w-64 h-64 p-3 text-center bg-primary text-white">
                  <div className="flex justify-center m-4">
                    <img className="vorteile__icon" src={icon_sparen} alt="Icon" />
                  </div>
                  <div><b>Geld sparen</b> durch Pay-as-you-Use Modell statt hoher Miete
                  </div>
                </div>

                <div className="w-64 h-64 p-3 text-center bg-white text-black">
                  <div className="flex justify-center m-4">
                    <img className="vorteile__icon" src={icon_konstant} alt="Icon" />
                  </div>
                  <div><b>Konstante monatliche Kosten</b> Keine Überraschung in deiner persönlichen Budgetplanung
                  </div>
                </div>

                <div className="w-64 h-64 p-3 text-center bg-secondaryYellowDark text-white">
                  <div className="flex justify-center m-4">
                    <img className="vorteile__icon" src={icon_abo} alt="Icon" />
                  </div>
                  <div>Dein Hotel-Abo ist<br/> <b>monatlich kündbar</b></div>
                </div>

              </div>
              <div className="flex flex-wrap justify-center flex-col md:flex-row md:w-64 lg:w-full">

                <div className="w-64 h-64 p-3 text-center bg-white text-black">
                  <div className="flex justify-center m-4">
                    <img className="vorteile__icon mb-3" src={icon_hotel} alt="Icon" />
                  </div>
                  <div>
                    <b>Hotelkomfort</b><br/> nutzen statt putzen
                  </div>
                </div>

                <div className="w-64 h-64 p-3 text-center bg-secondaryRedDark text-white">
                  <div className="flex justify-center m-4">
                    <img className="vorteile__icon" src={icon_steuer} alt="Icon" />
                  </div>
                  <div>
                    <b>Steuervorteil</b> <br/>Kosten sind absetzbar und Zweitwohnungsteuer entfällt
                  </div>
                </div>

                <div className="w-64 h-64 p-1 text-center bg-white text-black">
                  <div className="flex justify-center m-4">
                    <img className="vorteile__icon" src={icon_CO2} alt="Icon" />
                  </div>
                  <div><b>Reduzierter CO2 Fußabdruck</b> durch weniger Pendeln
                    und effizientere Nutzung von Wohnraum in Ballungsgebieten
                  </div>
                </div>

              </div>

            </div>

        </div>
      </div>
    </section>
 );
};

Advantages.propTypes = {
    contentModuleId : PropTypes.string.isRequired
}

export default Advantages;
