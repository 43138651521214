import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import NewsletterForm from '../components/newsletterForm';

const Contact = ({ contentModuleId }) => {

    const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutContact {
                edges {
                    node {
                        id
                        heading
                        description {
                            description
                        },
                        image {
                            fluid(quality: 100) {
                                ...GatsbyContentfulFluid
                            },
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const content = data.allContentfulLayoutContact.edges.find(edge => edge.node.id === contentModuleId);

    return (
        <section id="anmelden" className="contact container section mx-auto">
            <div className="contact__content">
                <h2 className="section__title" data-sal="fade" data-sal-easing="ease-in-cubic">{ content.node.heading }</h2>
                <NewsletterForm
                    description={content.node.description.description}
                    showCityInput={true}
                    source={'start'}
                />
            </div>
            <div className="contact__image">
                <div className="mx-auto" data-sal="slide-up" data-sal-delay="400" data-sal-duration="500">
                    <div className="contact__image-wrap">
                        <img src={ content.node.image.file.url } alt="Contact" className="mx-auto" />
                    </div>
                </div>
            </div>
        </section>
    );
};

Contact.propTypes = {
    contentModuleId : PropTypes.string.isRequired
}

export default Contact;
