import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

const people = [
  { name: '3 Nächte' },
  { name: '4 Nächte' },
  { name: '5 Nächte' },
  { name: '6 Nächte' },
  { name: '7 Nächte' },
  { name: '8 Nächte' },
  { name: '9 Nächte' },
  { name: '10 Nächte' },
  { name: '11 Nächte' },
  { name: '12 Nächte' },
]

export const ListBox = (options) => {
  const [selectedNights, setSelectedNights] = useState('default')
  const {fieldId, handlers} = options
  const {inputChangeHandler, inputBlurHandler, errors} = handlers

  const onChange = (newValue) => {
    setSelectedNights(newValue)
    inputChangeHandler({target:{name:fieldId,value:newValue}})
  }

  return (
    <div className="flex flex-row items-start items-center">
      <div className="w-full">
        <Listbox value={selectedNights} onChange={onChange}>
          <div className="relative">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left cursor-default focus:outline-none">
              <span className={`block truncate text-base ${(selectedNights === 'default') ? 'text-gray-400' : 'text-gray-800'}`}>
                {(selectedNights === 'default') ? 'z.B. 6 Nächte' : selectedNights.name}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded shadow max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                {people.map((person, personIdx) => (
                  <Listbox.Option
                    key={personIdx}
                    className={({ active }) =>
                      `${active ? 'text-gray-900 bg-gray-200' : 'text-gray-900'}
                            cursor-default select-none relative py-2 pl-10 pr-4`
                    }
                    value={person}
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={`${
                            selected ? 'font-bold' : 'font-normal'
                          } block truncate`}
                        >
                          {person.name}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? 'text-secondaryGray' : 'text-secondaryGray'
                            }
                                  absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  )
}
