import React from 'react';
import ahgz from '../assets/images/press_ahgz.svg';
import bay_stmi from '../assets/images/press_bayerisches_staatsministerium.webp';
import deutsche_startups from '../assets/images/press_deutsche_starups.png';
import gruenderland from '../assets/images/press_gruenderland_bayern.png';
import hotel_vor_9 from '../assets/images/press_hotel_vor_9.png';
import startbase from '../assets/images/press_startbase_full.webp';
import tageskarte from '../assets/images/press_tageskarte.png';


const Press = () => {
  return(
    <div className="">
      <div className="container px-4 md:px-28 xl:px-36 mx-auto flex flex-wrap justify-center">
        <div className="w-full lg:w-1/2 mb-8">
          <div className="font-bold text-gray-400">Gefördert durch:</div>
          <div className="flex flex-wrap space-x-3 md:space-x-8 items-center">
            <a href="https://www.gruenderland.bayern/neuigkeiten/artikel/die-gewinner-der-12-wettbewerbsphase-vonstartzuschuss-stehen-fest/" target="_blank" rel="nofollow"><img src={gruenderland} className="h-10 md:h-12" alt="Gründerland Bayern" /></a>
            <a href="https://www.stmwi.bayern.de/presse/pressemeldungen/pressemeldung/pm/403-2021/" target="_blank" rel="nofollow"><img src={bay_stmi} className="h-16 md:h-20" alt="Bayerisches Staatsministerium für Wirtschaft, Landesentwicklung und Energie" /></a>
          </div>
        </div>
        <div className="w-full lg:w-1/2 mb-8">
          <div className="font-bold text-gray-400">Bekannt aus:</div>
          <div className="flex flex-wrap space-x-3 md:space-x-5 mt-6">
            <a href="https://www.ahgz.de/hotellerie/news/buchungsportale-start-up-lanciert-hotel-abo-303750" target="_blank" rel="nofollow"><img src={ahgz} className="h-8" alt="AHGZ" /></a>
            <a href="https://www.tageskarte.io/hotellerie/detail/hotel-abo-start-up-myflexhome-gewinnt-gruenderwettbewerb.html" target="_blank" rel="nofollow"><img src={tageskarte} className="h-8" alt="Tageskarte" /></a>
            <a href="https://www.hotelvor9.de/inside/my-flex-home-startet-plattform-fuer-hotel-abos" target="_blank" rel="nofollow"><img src={hotel_vor_9} className="h-8" alt="Hotel Vor 9" /></a>
            <a href="https://www.startbase.de/news/ein-hotel-im-abo/" target="_blank" rel="nofollow"><img src={startbase} className="h-8" alt="Starbase" /></a>
            <a href="#" target="_blank" rel="nofollow"><img src={deutsche_startups} className="h-8" alt="Deutsche Startups" /></a>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Press
