import React, {useState} from 'react'
import axios from 'axios'
import {AutoSuggest} from '../components/autoSuggest'
import {ListBox} from '../components/listBox'
import {navigate} from 'gatsby'

const Search = (props) => {

  const inputDefaults = {
    city: '',
    nights: '',
  }

  const [input, setInput] = useState({...inputDefaults})
  const [errors, setErrors] = useState(new Set())
  const [forceUpdate, setForceUpdate] = useState(1)

  const inputChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.value
    setInput({
      ...input,
      [key]: value,
    })
    // if error exits, we want a direct re-evaluation. otherwise wait until blur
    errors.has(key) ? validateInput(event.target) : null
  }

  const checkboxChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.checked
    setInput({
      ...input,
      [key]: value,
    })
    // validate on every change
    validateInput({name:key, value:value})
  }

  const inputBlurHandler = (fieldId) => {
    const target = {name: fieldId, value: input[fieldId]}
    validateInput(target)
    setForceUpdate(forceUpdate+1)
  }

  const handlers = {
    inputChangeHandler: inputChangeHandler,
    inputBlurHandler: inputBlurHandler,
    checkboxChangeHandler: checkboxChangeHandler,
    input: input,
    errors: errors,
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await validateForm()
    if(errors.size > 0) {
      console.log('error')
      //console.log(errors)
      //console.log(input)
    } else {
      console.log('form ok')
      //console.log(input)
      const payload = {
        city: input.city,
        nights: input.nights.name || '',
      }
      axios.post('/api/booking/search',payload)
        .then ( response => {
          navigate('/stadt/'+input.city, {
            state: {
              city: input.city,
              nights: input.nights,
            }
          })
        })

    }
  }

  const validateForm = () => {
    //validateInput({name:'city', value:input['city']})
    //validateInput({name:'nights', value:input['nights']})
    setForceUpdate(forceUpdate+1) //hack to force rerender to show errors
  }

  const validateInput = (target) => {
    if(validationRuleContainsError(target.name, target.value)) {
      setErrors(errors => errors.add(target.name))
    } else {
      const newErrors = errors
      newErrors.delete(target.name)
      setErrors(newErrors)
    }
  }

  const validationRuleContainsError = (name, value) => {
    let error;
    switch (name) {
      case 'city':
        if (value === null || value === '') error = true;
        break;
      case 'nights':
        if (value === null || value === '') error = true;
        break;
      default:
        error = false;
    }
    return error;
  }

  return (
    <div id="search" className="search">
      <div className="container mx-auto">

        <div className="flex justify-center">

          <div className="flex flex-wrap justify-between xl:justify-center bg-primary rounded-lg p-1">

            <div className="bg-white rounded-lg py-1 px-2 mx-1 my-1 w-full lg:w-64 border-2 border-white focus-within:border-secondaryYellow">
              {AutoSuggest({
                fieldId: 'city',
                fieldName:'Dein Büro-Standort',
                handlers: handlers,
                errorText: 'Bitte gib eine gültige Stadt an.',
              })}
            </div>

            <div className="bg-white rounded-lg py-1 px-2 mx-1 my-1 w-full lg:w-64 border-2 border-white focus-within:border-secondaryYellow">
              <legend className="text-xs text-primaryHover">Abo-Nächte pro Monat</legend>
              <ListBox
                fieldId='nights'
                handlers={handlers}
              />
            </div>

            <button
              className="btn bg-primary border-white border-2 text-white hover:bg-primaryHover mx-1 my-1 w-full lg:w-32 focus:border-secondaryYellow"
              onClick={handleSubmit}
            >Abstimmen</button>

          </div>
        </div>
      </div>
    </div>
  )

}

export default Search;
