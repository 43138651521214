import React, {useState} from 'react';
import helpIcon from '../assets/svg/help.svg';
import Tippy from '@tippyjs/react';
import ReactSuggest from 'react-autosuggest';
import {axiosOnce} from '../utils/axiosOnce';
import axios from 'axios';

export const AutoSuggest = (options) => {
  const {fieldId, fieldName, required, handlers, errorText='Fehler', help=false} = options;
  const {inputChangeHandler, inputBlurHandler, errors} = handlers;

  const styleOK = 'appearance-none py-3 px-3 w-full text-base text-gray-700 leading-tight focus:outline-none'
  const styleNOK = 'appearance-none py-3 px-3 w-full text-base text-gray-700 leading-tight focus:outline-none'

  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getSuggestionValue = (suggestion) => {
    return suggestion.city
  }

  const renderSuggestion = (suggestion) => {
    return (
      <span>{suggestion.city}</span>
    )
  }

  const loadSuggestions = (value) => {
    setIsLoading(true)
    const payload = { city: value }

    let config = {
      method: "post",
      data: payload,
      url: "/api/address/city",
    }

    axiosOnce(config)
      .then (response => {
        setSuggestions(response.data)
        setIsLoading(false)
      })
      .catch(error => {
        if(!axios.isCancel(error)) console.log('API error')
        setIsLoading(false)
      });
  }

  const onChange = (event, { newValue }) => {
    setValue(newValue)
    inputChangeHandler(event)
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    loadSuggestions(value);
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const onSuggestionSelected = (event, suggestion) => {
    // function necessary to trigger input update and re-validation
    inputChangeHandler({target:{name:fieldId,value:suggestion.suggestionValue}})
  }

  const inputProps = {
    id: fieldId,
    name: fieldId,
    placeholder: 'z.B. Berlin',
    value,
    onChange: onChange,
    //onFocus: () => loadSuggestions('Ul'),
    onBlur: () => inputBlurHandler(fieldId),
    className: errors.has(fieldId) ? styleNOK : styleOK
  }

  const ErrorMessage = () => {
    return(<div className="text-red-700 text-xs mx-2 my-1 w-full">{errorText}</div>)
  }

  return(
    <div className="flex flex-col">
      {
        errors.has(fieldId)
          ? <legend className="text-xs text-red-700">{fieldName}</legend>
          : <legend className="text-xs text-primaryHover">{fieldName}</legend>
      }
      <div className="w-full">
        <ReactSuggest
          className=""
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </div>
      {
        // errors.has(fieldId) ? <ErrorMessage /> : ''
      }
    </div>
  )
}
