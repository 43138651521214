import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const Video = ({contentModuleId}) => {

  const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutVideo {
                edges {
                    node {
                        id
                        heading
                        description {
                            description
                        }
                    }
                }
            }
        }
    `);

  const content = data.allContentfulLayoutVideo.edges.find(edge => edge.node.id === contentModuleId);

  return (
    <section id="video" className="video section bg-warmGray">
      <div className="container mx-auto">
        <h2 className="section__title text-center" data-sal="fade" data-sal-easing="ease-in-cubic">{ content.node.heading }</h2>
        <p className="mb-4 w-full md:w-3/4" data-sal="slide-up" data-sal-easing="ease-in-cubic">{ content.node.description.description }</p>

        <div className="flex items-center justify-center mt-12">
          <div id="videoBox">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jof_4bY2OmQ?&autoplay=1&mute=1"
                    frameBorder="0" allowFullScreen allow="autoplay"></iframe>
          </div>
        </div>

      </div>
    </section>
  )

}

Video.propTypes = {
  contentModuleId : PropTypes.string.isRequired
}

export default Video;
